import { formatDate } from "./utils";

// Common Fields
const commonFields = {
    representativeFields: [
      { key: 'repName', header: 'Name of Representative', required: true },
      { key: 'institutionName', header: 'Institution Name', required: true },
      { key: 'repEmail', header: 'Email Address', required: true },
    ],
    debaterFields: (debaterNumber) => ([
      { key: `${debaterNumber}Name`, header: `Debater ${debaterNumber} Name ${debaterNumber < 4 ? '' : '(Optional)'}`, required: debaterNumber < 4 },
      { key: `${debaterNumber}Dob`, header: `Debater ${debaterNumber} Date of Birth ${debaterNumber < 4 ? '' : '(Optional)'}`, render: participant => formatDate(participant[`${debaterNumber}Dob`]), required: debaterNumber < 4 },
      { key: `${debaterNumber}Grade`, header: `Debater ${debaterNumber} Grade/Year Level ${debaterNumber < 4 ? '' : '(Optional)'}`, required: debaterNumber < 4 },
      { key: `${debaterNumber}Email`, header: `Debater ${debaterNumber} Email Address ${debaterNumber < 4 ? '' : '(Optional)'}`, required: debaterNumber < 4 },
    ])
};

  // Column Mapping based on event type
const eventColumnsMapping = {
    publicForumDebate: [
      ...commonFields.representativeFields,
      ...commonFields.debaterFields(1),
      ...commonFields.debaterFields(2),
      { key: 'division', header: 'Division' } // Division specific to this event type
    ],
    wsdc: [
      ...commonFields.representativeFields,
      ...commonFields.debaterFields(1),
      ...commonFields.debaterFields(2),
      ...commonFields.debaterFields(3),
      ...commonFields.debaterFields(4), // Optional, so you may need to check if data exists
      ...commonFields.debaterFields(5), // Optional
      { key: 'division', header: 'Division' }
    ],
    juniorDebate: [
      ...commonFields.representativeFields,
      ...commonFields.debaterFields(1),
      ...commonFields.debaterFields(2),
      ...commonFields.debaterFields(3)
    ],
    storytelling: [
      ...commonFields.representativeFields,
      { key: 'speakerName', header: 'Speaker Name', required: true },
      { key: 'speakerDob', header: 'Date of Birth', render: participant => formatDate(participant.speakerDob), required: true },
      { key: 'speakerGrade', header: 'Grade/Year Level', required: true },
      { key: 'speakerEmail', header: 'Email Address', required: true },
      { key: 'division', header: 'Division', required: true }
    ],
    originalOratory: [
      ...commonFields.representativeFields,
      { key: 'speakerName', header: 'Speaker Name', required: true },
      { key: 'speakerDob', header: 'Date of Birth', render: participant => formatDate(participant.speakerDob), required: true },
      { key: 'speakerGrade', header: 'Grade/Year Level', required: true },
      { key: 'speakerEmail', header: 'Email Address', required: true },
      { key: 'division', header: 'Division', required: true }
    ],
    impromptu: [
      ...commonFields.representativeFields,
      { key: 'speakerName', header: 'Speaker Name', required: true },
      { key: 'speakerDob', header: 'Date of Birth', render: participant => formatDate(participant.speakerDob), required: true },
      { key: 'speakerGrade', header: 'Grade/Year Level', required: true },
      { key: 'speakerEmail', header: 'Email Address', required: true },
      { key: 'division', header: 'Division', required: true }
    ],
    webinars: [
      { key: 'name', header: 'Name', required: true },
      { key: 'dob', header: 'Date of Birth', render: participant => formatDate(participant.dob), required: true },
      { key: 'email', header: 'Email Address', required: true },
      { key: 'occupation', header: 'Occupation', required: true },
    ],
    camps: [
      { key: 'name', header: 'Name', required: true },
      { key: 'schoolName', header: 'Institution/School Name', required: true },
      { key: 'email', header: 'Email Address', required: true },
      { key: 'grade', header: 'Grade', required: true },
    ],
    workshops: [
      { key: 'name', header: 'Name', required: true },
      { key: 'schoolName', header: 'Institution/School Name', required: true },
      { key: 'email', header: 'Email Address', required: true },
      { key: 'grade', header: 'Grade', required: true },
    ],
};

const formats = {
    publicForumDebate: 'Public Forum Debate',
    wsdc: 'WSDC',
    juniorDebate: 'Junior Debate',
    storytelling: 'Storytelling',
    originalOratory: 'Original Oratory',
    impromptu: 'Impromptu Speaking',
};

export function getColumnsForFormat(format) {
  return eventColumnsMapping[format] || []; // Return the columns for the given event type or an empty array if not found
}

export function getLabelFormat(format) {
    return formats[format] || ''; // Return the label for the given event type or an empty string if not found
}
