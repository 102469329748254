import { VStack } from "@chakra-ui/react";
import { Outlet } from 'react-router-dom';
import HeaderImage from "../components/HeaderImage";

function EventsRoot() {
  return (
    <VStack w='full' h='full' spacing={4}>
      <HeaderImage imageUrl='/Events.jpg' />
      <Outlet />
    </VStack>
  );
}

export default EventsRoot;
