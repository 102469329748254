import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

function SuccessRegistrationModal(props) {
  const message = props.type === 'memberships'
    ? 'Your registration has been successfully submitted. We will reach out to you via email.'
    : 'Your registration has been successfully submitted. We will send you a confirmation email along with further details.';

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Thank you for registering!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text fontSize='lg'>{message}</Text>
            </ModalBody>
            <ModalFooter>
            <Button variant="ghost" onClick={props.onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}

export default SuccessRegistrationModal;
