import { Box, Icon, Card, CardBody, Flex, Image, Text, HStack } from "@chakra-ui/react";
import { FaLocationPin } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { formatDate } from "../util/utils";
import { getLabelFormat } from "../util/competition_format_mapping";

export const Event = ({ event, eventType }) => {
  const imageUrl = event?.imageUrl || '/home-2.JPG';

  let dateContent = `${formatDate(event.startDate)} to ${formatDate(event.endDate)}`;

  if (!event.endDate) {
    dateContent = formatDate(event.date);
  }

  return (
    <Card borderRadius='2rem' w='full' cursor='pointer' h='300px'>
      <CardBody p={0} h='full'>
        <Link to={`/events/${event.id}?eventType=${eventType}`}>
          <Flex h='full'>
            <Image src={imageUrl} alt='cover photo' h='100%' objectFit='cover' borderLeftRadius='2rem' />

            <Flex direction='column' p='2rem'>
              <Text fontSize='3xl' fontWeight='bold' px='0' my='0.5rem'>{event.title}</Text>
              <Box flexGrow={1}>
                {eventType === 'competitions' && <Text fontSize='xl'  py='0'>Formats: {getLabelFormat(event.format)}</Text>}
                <Text fontSize='xl'  px='0'>Date: {dateContent}</Text>
                <Text fontSize='xl'  px='0'>Registration: {formatDate(event.startRegistrationDate)} to {formatDate(event.endRegistrationDate)}</Text>
              </Box>
              <HStack alignItems='center'>
                <Icon as={FaLocationPin} w={8} h={8} color='brand.900' />
                <Text fontSize='md'  nx='1rem' color='brand.900'>
                  {event.address}
                </Text>
              </HStack>
            </Flex>
          </Flex>
        </Link>
      </CardBody>
    </Card>
  );
};
