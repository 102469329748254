import { Box, Button, Flex, Grid, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useLocation, defer, useLoaderData, Await } from "react-router-dom";
import { useEffect, Suspense } from "react";
import Subscribe from "../components/Subscribe";
import { HomeEvent } from "../components/HomeEvent";
import { Link } from 'react-router-dom';
import { ContactUs } from "../components/ContactUs";
import HeaderImage from "../components/HeaderImage";
import SupportedCompetitionFormat from "../components/SupportedCompetitionFormat";

const API_URL = process.env.REACT_APP_API_URL;

function HomePage() {
  const { events } = useLoaderData();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#subscribe-section') {
      const element = document.getElementById('subscribe-section');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <VStack w='full' h='full' spacing={4}>
      <HeaderImage imageUrl={'/CoverPhoto.png'} />
      <Box p='3rem'>
        <HStack spacing={4}>
          <Box w='50%' mr='3rem'>
            <VStack spacing={4}>
              <Text fontSize='4xl' fontWeight='bold' color='secondary.900'>
                Global leaders, local impact.
              </Text>
              <Text as='p' fontSize='xl' fontWeight='medium' textAlign='justify'>
              The World Forensics League (WFL) is a global organization that aims to promote debate and public speaking among youths who are 18 years old and below. Our objective is to provide a platform for young individuals to hone their speaking and critical thinking skills and encourage them to become confident communicators. Through our initiatives, we aim to foster a community of young, enthusiastic, and informed leaders who can share their ideas and perspectives on various global issues.
              </Text>

              <Button size='lg' bg='brand.900' color='white' borderRadius='2rem'>
                <Link to='/memberships'>
                  Explore Membership
                </Link>
              </Button>
            </VStack>
          </Box>
          <Box w='50%'>
            <Image src='/home-1.JPG' alt='cover photo' w='100%' objectFit='cover' borderRadius='2rem' />
          </Box>
        </HStack>
      </Box>
      <Box w='100%' bg='brand.900'>
        <Text fontSize='2xl' color='white' p='2rem' px='5rem'>
          Upcoming Events
        </Text>
        <Box px='5rem'>
          <Suspense fallback={<p style={{textAlign: 'center', color: 'white'}}>Loading...</p>}>
            <Grid templateColumns='repeat(3, 1fr)' gap={6}>
              <Await resolve={events}>
                {(loadedEvents) => (
                  loadedEvents.map((event) => (
                    <HomeEvent key={event.id} event={event} />
                  ))
                )}
              </Await>
            </Grid>
          </Suspense>
        </Box>
        <Link to={'/events'}>
          <Flex justifyContent='center'>
            <Button size='lg' bg='white' color='brand.900' borderRadius='2rem' m='2rem' p='1rem' px='2rem'>See All Events</Button>
          </Flex>
        </Link>
      </Box>

      <SupportedCompetitionFormat />

      <Subscribe />

      <ContactUs />
    </VStack>
  );
}

export default HomePage;

async function loadEvents(eventType) {
  const response = await fetch(`${API_URL}/${eventType}`);

  if (!response.ok) {
    return [];
  }

  const data = await response.json();

  return data?.data?.events || [];
}

export const loader = () => {
  return defer({
    events: loadEvents('events-list'),
  });
};

