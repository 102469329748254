import { Box } from '@chakra-ui/react';

function HeaderImage({ imageUrl, ...props }) {
  return (
    <Box
      w="100%"  // Your desired width
      h="650px"  // Your desired height
      bgImage={`url(${imageUrl})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      {...props}
    >
    </Box>
  );
}

export default HeaderImage;
