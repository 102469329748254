import { useDisclosure, Box, Flex, Image, Text, VStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Square, Button, Spacer } from "@chakra-ui/react";
import { ChevronRightIcon } from '@chakra-ui/icons';
import { defer, redirect, useLoaderData, Await, useSearchParams } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { Event } from "../components/Event";
import { formatDate } from "../util/utils";
import { CompetitionRegistrationModal } from "../components/CompetitionRegistrationModal";
import SuccessRegistrationModal from "../components/SuccessRegistrationModal";

const API_URL = process.env.REACT_APP_API_URL;

function EventDetails() {
  const { event } = useLoaderData();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onClose: onCloseSuccess } = useDisclosure();
  const eventType = searchParams.get('eventType');
  const success = searchParams.get('success');

  useEffect(() => {
    if (success === 'true') {
      onClose();
    }
  }, [success, onClose, searchParams, setSearchParams]);

  const onCloseSuccessHandler = () => {
    onCloseSuccess();
    searchParams.delete('success');
    setSearchParams(searchParams);
  };

  return (
    <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
        <Await resolve={event}>
          {(loadedEvent) => (
            <VStack w='full' h='full' spacing={4}>
              <Flex w='full' p={4} py={10} px='5rem' alignItems='baseline'>
              <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                <BreadcrumbItem>
                  <BreadcrumbLink href='/events'>Events</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink href={`${loadedEvent.id}?eventType=${eventType}`} isCurrentPage>Event Details</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              </Flex>
              <Box bg='brand.900' h='30rem' w='full' p={20}>
                <Event event={loadedEvent} eventType={eventType} />
              </Box>
              <Box px='5rem' my='3rem' w='full' mb='10rem'>
                <VStack spacing={8} alignItems='baseline'>
                  <Text fontSize='2xl' p='0'>
                    Event Details
                  </Text>
                  <Flex w='full' alignItems='center' h={40}>
                    <Square h='100%' w='15%' bg='white' border='1px' borderColor='gray.900'>
                      <Image src='/logo.png' alt='logo photo' objectFit='cover' p={2} />
                    </Square>
                    <Flex direction='column' p='2rem' flexGrow={1}>
                      <Box flexGrow={1}>
                        {eventType === 'competitions' && (
                          <>
                          <Text fontSize='md'  py='0'>Host: {loadedEvent.hosts}</Text>
                          <Text fontSize='md'  px='0'>Formats: {loadedEvent.format}</Text>
                          </>
                        )}
                        <Text fontSize='md'  px='0'>Date: {formatDate(loadedEvent.startRegistrationDate)} to {formatDate(loadedEvent.endRegistrationDate)}</Text>
                      </Box>
                      {eventType === 'competitions' && (
                        <Text fontSize='xl' nx='2rem' color='brand.900' fontWeight='bold'>
                          ¥ {(+loadedEvent.price).toFixed(2)}
                        </Text>
                      )}
                      <Button bg='brand.900' color='white' borderRadius='2rem' p='1rem' px='2rem' mt={1} w={40} onClick={onOpen}>Register</Button>
                    </Flex>
                    <Spacer />
                  </Flex>
                </VStack>
              </Box>

              <CompetitionRegistrationModal isOpen={isOpen} onClose={onClose} competition={loadedEvent} eventType={eventType} />
              <SuccessRegistrationModal isOpen={success === 'true'} onClose={onCloseSuccessHandler} competition={loadedEvent} />
            </VStack>
          )}
        </Await>
    </Suspense>
  );
}

export default EventDetails;

async function loadEvent(eventId, eventType) {
  const response = await fetch(`${API_URL}/${eventType}/${eventId}`);

  if (!response.ok) {
    return null;
  }

  const data = await response.json();
  const event = data?.data?.event;

  return event;
}

export const loader = async ({ request, params }) => {
  const eventId = params.id;
  const { searchParams } = new URL(request.url);
  const eventType = searchParams.get('eventType') || 'competitions';

  const event = await loadEvent(eventId, eventType);

  if (!event) {
    return redirect('/events');
  }

  return defer({
    event,
  });
};
