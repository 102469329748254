import { Box, Image, Text, VStack } from "@chakra-ui/react";
import { redirect, defer, useLoaderData, Await } from 'react-router-dom';
import { Suspense } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

function UpdateDetailsPage() {
  const { update } = useLoaderData();

  return (
    <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
      <Await resolve={update}>
        {(loadedUpdate) => (
          <VStack w='full' h='full' spacing={4}>
            <Image src={loadedUpdate.imageUrl || '/home-5.JPG'} alt='cover photo' w='100%' objectFit='cover' />
            <Box p='3rem' px='5rem' alignItems='baseline' w='full'>
              <Text fontSize='2xl' fontWeight='bold' color='brand.900'>
                {loadedUpdate.title}
              </Text>

              <Box p={5} dangerouslySetInnerHTML={{ __html: loadedUpdate.content }} />
            </Box>
          </VStack>
        )}
      </Await>
    </Suspense>
  );
}

export default UpdateDetailsPage;

async function loadEvent(eventId, eventType) {
  const response = await fetch(`${API_URL}/${eventType}/${eventId}`);

  if (!response.ok) {
    return null;
  }

  const data = await response.json();
  const event = data?.data?.event;

  return event;
}

export const loader = async ({ params }) => {
  const updateId = params.id;
  const event = await loadEvent(updateId, 'updates');

  if (!event) {
    return redirect('/updates');
  }

  return defer({
    update: event,
  });
};

