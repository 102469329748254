import { Box, Card, CardBody, Flex, GridItem, Image, Text, VStack } from "@chakra-ui/react";
import { Link } from 'react-router-dom';

export const Update = ({ update }) => {
  const imageUrl = update?.imageUrl || '/home-1.JPG';

  function removeHtmlTags(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  }

  function truncateContent(content, maxLength) {
    const contentWithoutTags = removeHtmlTags(content);

    if (contentWithoutTags.length <= maxLength) return contentWithoutTags;

    const truncated = contentWithoutTags.slice(0, maxLength) + '...';
    return (
      <>
        {truncated}
        <Link to={update.id}>
          <Text as='span' color='brand.900'>READ MORE</Text>
        </Link>
        {/* Update /your-read-more-link to where you want the link to point */}
      </>
    );
  }

  const maxLength = 50; // Set this to whatever character limit you want
  const TruncatedContent = () => truncateContent(update.content, maxLength);

  return (
    <GridItem style={{ minWidth: '400px', minHeight: '400px' }}>
      <Card borderRadius='2rem'>
        <CardBody p={0} borderRadius='2rem'>
          <VStack spacing={2}>
            <Image src={imageUrl} alt='cover photo' w='100%' objectFit='cover' h='20rem' borderTopRadius='2rem' />
            <Flex direction='column' p={5} alignItems='baseline' width='full'>
              <Text fontSize='xl' fontWeight='bold'>
                {update.title}
              </Text>
              {/* <Box p={5} dangerouslySetInnerHTML={{ __html: update.content }} /> */}
              <Box
                p={5}
                maxHeight='5rem'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                <TruncatedContent />
              </Box>
            </Flex>
          </VStack>
        </CardBody>
      </Card>
    </GridItem>
  );
};
