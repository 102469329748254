import { Select, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Form, redirect, useSubmit } from 'react-router-dom';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useState } from 'react';
import { getColumnsForFormat } from '../util/competition_format_mapping';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

export const CompetitionRegistrationModal = (props) => {
  const [team, setTeam] = useState({});
  const submit = useSubmit();
  const format = props.eventType === 'competitions'
    ? props.competition.format
    : props.eventType;
  const columns = getColumnsForFormat(format);

  const onChangeHandler = (eventInput) => {
      const { name, value } = eventInput.target;
      setTeam({ ...team, [name]: value });
  };

  const generateFields = (column) => {
    if (column.key.toLowerCase().includes('dob')) {
      return (
        <FormControl mt={4} key={column.key}>
          <FormLabel>{column.header}</FormLabel>
          <SingleDatepicker
              name={column.key}
              date={team[column.key] || new Date()}
              onDateChange={(val) => onChangeHandler({
                  target: {
                      name: column.key,
                      value: val,
                  }
              })}
              required={column.required}
          />
        </FormControl>
      );
    }

    if (column.key.toLowerCase().includes('division')) {
      return (
        <FormControl mt={4} key={column.key}>
          <FormLabel>{column.header}</FormLabel>
          <Select name={column.key} onChange={onChangeHandler} required={column.required}>
            <option value='Middle School (Under 16)'>Middle School (Under 16)</option>
            <option value='High School (Under 19)'>High School (Under 19)</option>
          </Select>
        </FormControl>
      );
    }

    if (column.key.toLowerCase() === 'occupation') {
      const isOccupationTextRequired = () => {
        return team[column.key] === 'Other';
      };

      return (<>
        <FormControl mt={4} key={column.key}>
          <FormLabel>{column.header}</FormLabel>
          <Select name={column.key} onChange={onChangeHandler} required={column.required}>
            <option value='Teacher'>Teacher</option>
            <option value='Student'>Student</option>
            <option value='Other'>Other</option>
          </Select>
        </FormControl>
        <FormControl mt={4} key='occupationOther' disabled={!isOccupationTextRequired()}>
          <FormLabel>Other</FormLabel>
          <Input type='text' name='occupationOther' onChange={onChangeHandler} required={isOccupationTextRequired()} disabled={!isOccupationTextRequired()} />
        </FormControl>
      </>)
    }

    const inputType = column.key.includes('email') ? 'email' : 'text';

    return (
      <FormControl mt={4} key={column.key}>
        <FormLabel>{column.header}</FormLabel>
        <Input type={inputType} name={column.key} onChange={onChangeHandler} required={column.required} />
      </FormControl>
    );
  }

  const fields = columns.map(column => generateFields(column));

  const submitHandler = (eventInput) => {
    eventInput.preventDefault();
    const formData = new FormData(eventInput.target);

    columns.forEach(column => {
      if (column.key === 'occupation' && team[column.key] === 'Other') {
        formData.set('occupation', team.occupationOther);
        return;
      }

      if (!formData.get(column.key)) {
        formData.append(column.key, team[column.key]);
      }
    });

    formData.append('format', format);
    formData.append('eventType', props.eventType);
    formData.append('competitionId', props.competition.id);
    submit(formData, { action: `/api/register`, method: 'POST' });
    setTeam({});
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Form onSubmit={submitHandler}>
          <ModalHeader>Register for {props.competition.title}</ModalHeader>
          <ModalCloseButton onClick={props.onClose} />
          <ModalBody>
            <Input
                display='none'
                type='text'
                name='id'
                value={props.competition.id}
                onChange={onChangeHandler}
            />
            {fields.map(field => field)}
          </ModalBody>

          <ModalFooter>
            <Button type='submit'>Register</Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export const action = async ({ request }) => {
  const method = request.method;
  const data = await request.formData();

  const eventType = data.get('eventType');
  const fields = getColumnsForFormat(data.get('format')).map(column => column.key);

  const participant = fields.reduce((acc, fieldName) => {
      acc[fieldName] = data.get(fieldName);
      return acc;
  }, {});

  const options = {
      method,
      body: JSON.stringify(participant),
      headers: {
          'Content-Type': 'application/json',
      },
  };

  await fetch(
      `${API_URL}/${eventType}/${data.get('id')}/register`,
      options,
  );

  return redirect(`/events/${data.get('id')}?eventType=${eventType}&success=true`);
};
