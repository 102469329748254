import { Link, Box, Icon, Flex, Text } from "@chakra-ui/react";
import { VscFilePdf } from 'react-icons/vsc';
import { FaFileWord } from 'react-icons/fa';
import { formatDate } from "../util/utils";

const extensionMapping = {
  '.pdf': 'PDF',
  '.doc': 'Word',
  '.docx': 'Word',
};

export const ResourceFile = (props) => {
  const {
    createdAt,
    file,
    name
  } = props.resource;

  const fileIconMapping = {
    'PDF': VscFilePdf,
    'Word': FaFileWord,
  };

  const fileType = extensionMapping[file.extension] || 'File';

  return (
    <Link href={file.url} isExternal download>
      <Flex align='center' cursor='pointer'>
        <Icon as={fileIconMapping[fileType]} mr={2} w={6} h={6}  />
        <Box display='flex' align='center' role="group">
          <Text fontSize='xl' _groupHover={{ color: 'brand.900', textDecoration: 'underline' }}>{name} ({fileType})</Text>
          &nbsp;
          <Text fontSize='xl' color='gray.500' _groupHover={{ color: 'brand.900', textDecoration: 'underline' }}>{formatDate(createdAt)}</Text>
        </Box>
      </Flex>
    </Link>
  );
};
