import { format } from 'date-fns';

export function formatDate(date) {
    return format(new Date(date), 'dd MMM yyyy');
};

// capitalize first letter and remove s from the end if there is
export function formatEventType(eventType) {
    return eventType.charAt(0).toUpperCase() + eventType.slice(1).replace(/s$/, '');
}
