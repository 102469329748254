import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

import Navbar from './Navbar';

function RootLayout() {
  return (
    <>
      <Flex direction='column' minH='100vh'>
        <Navbar />
        <Flex flexGrow='1' minH='100vh'>
          <Outlet />
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default RootLayout;
