import { Flex, HStack, Image, Link, Spacer, Icon, Grid, GridItem } from "@chakra-ui/react";

import { BiLogoFacebook, BiLogoInstagram } from 'react-icons/bi';


function Footer() {
  return (
    <Flex bg="secondary.900" color="secondary.100" w="100%" minH='8rem' p={4} direction='column'>
      <Spacer />
      <Grid templateColumns='repeat(3, 1fr)' gap={6}>
        <GridItem w='100%' h='10'>
          <HStack spacing='7rem'>
            <Link href="#" color="white">
              Terms and Conditions
            </Link>
            <Link href="#" color="white">
              Privacy Policy
            </Link>
          </HStack>
        </GridItem>
        <GridItem w='100%' h='10'>
          <Image src='/SecondaryLogo_White.png' alt='logo' w='100px' objectFit='contain' m='0 auto' />
        </GridItem>
        <GridItem w='100%' h='10'>
          <Flex align='end' justify='flex-end'>
            <Link href="https://www.facebook.com/profile.php?id=100094612621711" target="_blank">
              <Icon as={BiLogoFacebook} w={8} h={8} color='white' />
            </Link>
            <Link href="https://www.instagram.com/worldforensicsleague" target="_blank">
              <Icon as={BiLogoInstagram} w={8} h={8} color='white' />
            </Link>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default Footer;
