import { Box, Flex, Image, Text, Badge, Grid, GridItem } from "@chakra-ui/react";

const publicSpeaking = [
  {
    name: 'Storytelling',
    description: 'Speakers write and deliver an original story in their creative style',
  },
  {
    name: 'Original Oratory',
    description: 'Speakers deliver a memorized persuasive speech of their own writing',
  },
  {
    name: 'Impromptu Speaking',
    description: 'Speakers deliver a speech without prior preparation upon being given a prompt',
  }
];

const debate = [
  {
    name: 'Public Forum Debate',
    description: '2v2 debate about a prepared topic, typically centered around current events',
  },
  {
    name: 'WSDC',
    description: '3v3 debate about prepared and impromptu topics about a diverse pool of relevant issues',
  },
  {
    name: 'Junior Debate',
    description: '3v3 debate about prepared and impromptu topics, catered to younger debaters',
  }
];

function SupportedCompetitionFormat() {
  return (
    <Box w='100%'p='2rem' px='5rem'>
      <Text as='h2' fontSize='3xl' fontWeight='bold' mb='1rem' color='brand.900'>
        Supported Competition Formats
      </Text>

      <Flex direction='column' mt={15}>
        <Text fontSize='2xl'>
          Public Speaking
        </Text>
        <Box mt={5} h={650}>
          <Image src='/Home - Public Speaking.jpg' alt='cover photo' w='100%' h='100%' objectFit='cover' objectPosition='50% 35%' />
        </Box>

        <Grid templateColumns='repeat(3, 1fr)' gap={8} mt={10}>
          {publicSpeaking.map((format) => (
            <GridItem key={format.name}>
              <Flex direction='column' alignItems='center'>
                <Badge px={4} bg='brand.900' color='white' fontSize='xl' fontWeight='bold' mb={5} borderRadius={8}>{format.name}</Badge>
                <Text fontSize='lg'>{format.description}</Text>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Flex>

      <Flex direction='column' mt={30}>
        <Text fontSize='2xl'>
          Debate
        </Text>
        <Box mt={5} h={650}>
          <Image src='/Debate.JPG' alt='cover photo' w='100%' h='100%' objectFit='cover' objectPosition='50% 35%' />
        </Box>

        <Grid templateColumns='repeat(3, 1fr)' gap={8} mt={10}>
          {debate.map((format) => (
            <GridItem key={format.name}>
              <Flex direction='column' alignItems='center'>
                <Badge px={4} bg='brand.900' color='white' fontSize='xl' fontWeight='bold' mb={5} borderRadius={8}>{format.name}</Badge>
                <Text fontSize='lg'>{format.description}</Text>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Flex>

    </Box>
  );
}

export default SupportedCompetitionFormat;
