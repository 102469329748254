import { Box, Flex, Input, Text, Button } from "@chakra-ui/react";
import { useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

function Subscribe() {
  const [email, setEmail] = useState('');
  const [label, setLabel] = useState('');

  const handleEmailChange = (event) => {
    setLabel('');
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setLabel('Invalid email address.');
      return;
    }

    await fetch(`${API_URL}/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    setLabel('You have successfully subscribed to our email updates.');
    setEmail('');
  };

  const isValidEmail = (email) => {
    return email && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
  };

  return (
    <Box w='100%' id='subscribe-section'>
      <Flex direction='column' align='center' justify='center' p='3rem'>
        <Input placeholder='Enter your email address' w='30%' borderRadius='2rem' onChange={handleEmailChange} value={email} />
        <Text fontSize='xl' fontWeight='bold' color='red.900' pt='1rem'>
          {label}
        </Text>
        <Text fontSize='3xl' fontWeight='bold' color='secondary.900'>
          Want to stay updated about WFL events?
        </Text>
        <Text fontSize='2xl' fontWeight='bold'>
          Subscribe to our email updates.
        </Text>
        <Button bg='brand.900' color='white' borderRadius='2rem' m='2rem' p='1rem' px='2rem' onClick={handleSubmit}>Subscribe</Button>
      </Flex>
    </Box>
  );
}

export default Subscribe;
