import { Select, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Form, redirect, useSubmit } from 'react-router-dom';
import { useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5006';

const getFields = (type) => {
  switch(type) {
    case 'Non-School':
      return [
        { key: 'organizationName', header: 'Organization Name', required: true },
        { key: 'country', header: 'Country', required: true },
        { key: 'contactPerson', header: 'Contact Person', required: true },
        { key: 'email', header: 'Email', required: true },
      ];
    case 'Individual':
      return [
        { key: 'fullName', header: 'Full Name', required: true },
        { key: 'country', header: 'Country', required: true },
        { key: 'email', header: 'Email', required: true },
      ];
    default:
      return [
        { key: 'schoolName', header: 'School Name', required: true },
        { key: 'country', header: 'Country', required: true },
        { key: 'contactPerson', header: 'Contact Person', required: true },
        { key: 'email', header: 'Email', required: true },
      ];
  }
}

export const MembershipRegistrationModal = (props) => {
  const [team, setTeam] = useState({});
  const submit = useSubmit();

  const onChangeHandler = (eventInput) => {
      const { name, value } = eventInput.target;
      setTeam({ ...team, [name]: value });
  };

  const generateFields = () => {
    const fields = getFields(team.type);

    return fields.map(field => {
      return (
        <FormControl mt={4} key={field.key}>
          <FormLabel>{field.header}</FormLabel>
          <Input name={field.key} onChange={onChangeHandler} required={field.required} />
        </FormControl>
      );
    });
  }

  const forms = generateFields();

  const submitHandler = (eventInput) => {
    eventInput.preventDefault();
    const formData = new FormData(eventInput.target);
    submit(formData, { action: `/api/memberships`, method: 'POST' });
    setTeam({});
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Form onSubmit={submitHandler}>
          <ModalHeader>Register for Membership</ModalHeader>
          <ModalCloseButton onClick={props.onClose} />
          <ModalBody>
            <FormControl mt={4} key='type'>
                <FormLabel>Type of Membership</FormLabel>
                <Select name='type' onChange={onChangeHandler} required>
                    <option value='School'>School</option>
                    <option value='Non-School'>Non-School</option>
                    <option value='Individual'>Individual</option>
                </Select>
            </FormControl>
            {forms.map(form => form)}
          </ModalBody>

          <ModalFooter>
            <Button type='submit'>Register</Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export const action = async ({ request }) => {
  const method = request.method;
  const data = await request.formData();

  const type = data.get('type');
  const fields = getFields(type).map(field => field.key);

  const participant = fields.reduce((acc, fieldName) => {
      acc[fieldName] = data.get(fieldName);
      return acc;
  }, {});

  participant.type = type;

  const options = {
      method,
      body: JSON.stringify(participant),
      headers: {
          'Content-Type': 'application/json',
      },
  };

  await fetch(
      `${API_URL}/memberships`,
      options,
  );

  return redirect(`/memberships?success=true`);
};
