import { Box, Button, Flex, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import { NavLink, Link } from 'react-router-dom';  // import from react-router-dom

function Topbar() {
  const links = [
    { to: '/', label: 'Home' },
    { to: '/memberships', label: 'Memberships' },
    { to: '/events', label: 'Events' },
    { to: '/resources', label: 'Resources' },
    { to: '/updates', label: 'Updates' },
  ];

  return (
    <Box w='100%' px='8rem'>
      <HStack spacing='8rem'>
        <Image src='/logo.png' alt='logo' boxSize='150px' objectFit='contain' />
        <HStack spacing='8rem'>
        {links.map(link => (
            <NavLink
              key={link.to}
              to={link.to}
              style={({ isActive }) => {
                return {
                  textDecoration: 'none',
                  color: isActive ? '#d64123' : 'gray',
                };
              }}
            >
              <Text fontSize='lg'>{link.label}</Text>
            </NavLink>
          ))}
        </HStack>
        <Flex flexGrow='1'>
          <Spacer />
          <Link to='/#subscribe-section'>
            <Button size='lg' bg='brand.900' color='white' borderRadius='2rem'>Contact Us</Button>
          </Link>
        </Flex>
      </HStack>
    </Box>
  );
}

export default Topbar;
