import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Cosmica';
        src: url('../public/cosmica/Cosmica Regular.otf') format('font/opentype');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'Cosmica';
        src: url('../public/cosmica/Cosmica Bold.otf') format('font/opentype');
        font-weight: bold;
        font-style: normal;
      }
      `}
  />
)

export default Fonts;
