import { Link, useDisclosure, Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Text, VStack, Icon, Flex, Button } from "@chakra-ui/react";
import HeaderImage from "../components/HeaderImage";
import { BsChevronDown } from 'react-icons/bs';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MembershipRegistrationModal } from "../components/MembershipRegistrationModal";
import SuccessRegistrationModal from "../components/SuccessRegistrationModal";

function MembershipsPage() {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onClose: onCloseSuccess } = useDisclosure();
  const success = searchParams.get('success');

  useEffect(() => {
    if (success === 'true') {
      onClose();
    }
  }, [success, onClose, searchParams, setSearchParams]);

  const onCloseSuccessHandler = () => {
    onCloseSuccess();
    searchParams.delete('success');
    setSearchParams(searchParams);
  };


  const membershipTypes = [
    {
      type: 'School Membership',
      description: 'This type of membership is intended for school teams that participate in nationally recognized K12 systems. Schools can register their teams as members of the WFL and gain access to resources and opportunities to improve their skills and compete at a high level. School membership provides a platform for students to develop their skills in forensics activities and to represent their school at national and internationalevents.',
    },
    {
      type: 'Non-School Membership',
      description: 'This type of membership is intended for organizations that are not affiliated with schools, such as debate academies, private institutions, or social groups that participate in debate and public speaking activities. Non-school members can register their teams as members of the WFL and gain access to resources and opportunities to improve their skills and compete at a high level. This membership option provides an opportunity for individuals who are not associated with schools to participate in forensics activities and compete at a high level.',
    },
    {
      type: 'Individual Membership',
      description: 'This type of membership is intended for coaches, teachers, parents, and students under the age of 18 who do not identify with any schools or non-school members. Individual members can register themselves as members of the WFL and gain access to resources and opportunities to improve their skills and compete at a high level. This membership option provides an opportunity for individuals who are not associated with any organization to participate in forensics activities and compete at a high level.',
    }
  ];

  const membershipDetails = [
    {
      header: 'Competition Structure',
      body: (
        <Box fontSize='xl' p={10} pt={1}>
          The competition structure in the World Forensics League is designed to provide opportunities for individuals and teams to compete at different levels of difficulty and scale. The competition structure is divided into several levels based on the number of participants and the level of competition. The different levels of competition available in the WFL are:
          <Box p={10} py={2}>
            <ol>
              <li>School-Based Club Activities: This is the lowest level of competition, where annual speech and debate competitions are held within schools. These competitions are typically organized by school clubs and are open to students within the school.</li>
              <li>Social Club Competitions: Parallel to school-based club activities, social clubs with no more than 50 participants can organize their own competitions. This level is intended for organizations that are not affiliated with schools but are interested in organizing small-scale competitions.</li>
              <li>Cluster Activities: This level is intended for competitions organized by multiple clubs within a specific geographic region. A cluster competition is held when more than five clubs or 150 participants come together to compete.</li>
              <li>Zonal Competitions: This level is intended for competitions organized by multiple clusters. A zonal competition is held when more than five clusters or 25 clubs or 250 participants come together to compete.</li>
              <li>Regional Competitions: This level is intended for larger competitions that involve multiple zones. A regional competition is held when 10 clusters or 50 clubs or 500 participants come together to compete.</li>
              <li>National Competitions: This level is intended for competitions organized by the national member of the WFL or the WFL committee. A national competition is held when more than 700 participants come together to compete.</li>
              <li>Continental Competitions: This level is intended for top-performing teams from each nation who are invited to compete at the continental level.</li>
              <li>World Competitions: This is the final level of competition, where the top-performing teams from each continent are invited to compete at the world level.</li>
            </ol>
          </Box>
          The competition structure in the WFL is designed to provide opportunities for individuals and teams to progress and compete at different levels of difficulty and scale. The WFL is committed to providing a fair and inclusive platform for all individuals and teams to showcase their skills and compete at a high level.
        </Box>
      )
    },
    {
      header: 'Activities',
      body: (
        <Box fontSize='xl' p={10} pt={1}>
          The World Forensics League will organize a range of activities to promote debate and public speaking among youths who are 18 years old and below. These activities are designed to help members develop their skills and confidence in public speaking and debate. Some of the activities include:
          <Box p={10} py={2}>
            <ul>
              <li>Online training programs on public speaking and debate skills. Members can access these programs at any time and from any location, making it easier for them to participate in the league's activities.</li>
              <li>Conferences and seminars on various global issues, where young people can present their views and engage in constructive discussions. These events provide members with a platform to share their perspectives and engage in meaningful dialogue with their peers.</li>
            </ul>
          </Box>
          National Memberships are encouraged to organize national-level activities for their leaders and outstanding performers. These activities can include workshops, tournaments, and other events that promote the growth and development of forensics activities in their country.
        </Box>
      )
    },
    {
      header: 'How to Get Involved',
      body: (
        <Box fontSize='xl' p={10} pt={1}>
          <Text as='h2' fontSize='2xl' fontWeight='bold'>
            School Membership
          </Text>
          <Text as='p' fontSize='xl'>
            School Membership in the World Forensics League is designed to provide schools with access to resources and opportunities to improve their students' skills in forensics activities. School Memberships are free and last for three years per application.<br/><br/>
            As part of their membership obligations, schools are expected to participate in at least one regional-level competition per semester. Additionally, they are required to have an organization committee consisting of five members, with at least one supervising teacher. The committee should be responsible for overseeing the school's forensics activities and ensuring that they are conducted in accordance with the WFL's guidelines and rules.<br/><br/>
            School Memberships also require schools to recruit at least ten new members every year. This is to ensure that the school is actively promoting and growing forensics activities within their community. Schools that are unable to meet these requirements may be placed on suspension, with assistance from the National Committee to help correct their operations.
          </Text>

          <Text as='h2' fontSize='2xl' fontWeight='bold' mt={5}>
            Non-School Membership
          </Text>
          <Text as='p' fontSize='xl'>
            Non-School Membership in the World Forensics League is designed to provide organizations with access to resources and opportunities to improve their members' skills in forensics activities. Non-School Memberships are free, but they must be renewed every year.<br/><br/>
            As part of their membership obligations, non-school members should submit a list of organizing committee members and a planned schedule of events for the year. Non-school members are also expected to recruit no less than 10 new members a year and participate in no less than one regional level competition per semester.<br/><br/>
            Non-school membership applicants should have a fixed venue for weekly activities and must meet at least once a week. This is to ensure that members have regular opportunities to improve their skills and participate in forensics activities. Additionally, non-school members should be committed to promoting and growing forensics activities within their community.
          </Text>

          <Text as='h2' fontSize='2xl' fontWeight='bold' mt={5}>
            Individual Membership
          </Text>
          <Text as='p' fontSize='xl'>
            Individual Membership in the World Forensics League is a paid membership that costs 100 USD per year. This fee goes towards maintaining the operational costs of the WFL.<br/><br/>
            Individual Members should apply directly to the WFL and have their membership status updated with the National Committee in their country. Individual Memberships are open to coaches, teachers, parents, and students under the age of 18 who do not identify with any school or non-school members.<br/><br/>
            Individual Memberships provide individuals with access to WFL's resources, which include training materials, webinars, and virtual practice sessions. Members are also eligible to participate in international debate and public speaking competitions organized by the WFL.<br/><br/>
            Students who require financial assistance for their membership should indicate this in their application to the WFL. The WFL is committed to providing a fair and inclusive platform for all individuals to showcase their skills and compete at a high level, regardless of their financial situation.
          </Text>
        </Box>
      )
    },
    {
      header: 'Membership Scoring System',
      body: (
        <Box fontSize='xl' p={10} pt={1}>
          <Text as='p' fontSize='xl'>
            The membership scoring system of the World Forensics League (WFL) is a comprehensive approach designed to recognize and reward the efforts of its members in competitive achievements and community activities related to forensics. The purpose of this system is to encourage and motivate members to excel in their performance and lead initiatives that increase awareness and participation in forensics activities.
          </Text>
          <br/>
          <Text as='p' fontSize='xl'>
            The system works by assigning points to individuals based on their achievements and results attained in competitions or community activities. The points are tiered and ranked to reflect the level of achievement, with higher scores being awarded for more significant accomplishments. For example, a member who wins a national championship would receive more points than someone who places third in a regional competition.
          </Text>
          <br/>
          <Text as='p' fontSize='xl'>
            The scores are updated after competitions submit their result tabulation or activities submit their post-activity reports. This ensures that members receive recognition for their efforts in a timely and accurate manner. The scores are then ranked to determine the top performers, who are invited to an annual gala dinner for honorable mentions.
          </Text>
          <br/>
          <Text as='p' fontSize='xl'>
            By recognizing and rewarding the efforts of its members, the WFL aims to attract the best debaters and orators in the world and also the most passionate individuals to help grow these activities all over the world. The membership scoring system is an important tool for achieving this goal, as it provides a clear and objective way to evaluate and acknowledge the contributions of members. It also helps to foster a sense of community and camaraderie among members, as they strive to improve their performance and help others do the same.
          </Text>
          <br/>
          <Text as='p' fontSize='xl'>
            In addition to the annual gala dinner, the WFL also hosts a summer event to gather and recognize the top performers of the year. This event provides an opportunity for members to come together and share their experiences, tips, and strategies for success. By hearing from the best and brightest in the community, other members can learn from their achievements and be inspired to follow in their footsteps. The event also serves as a platform for networking, fostering new relationships and collaborations among members. Overall, this event is an excellent way to celebrate the achievements of the WFL community, inspire future success, and promote the growth of forensics activities worldwide.
          </Text>
        </Box>
      )
    },
  ];


  return (
    <VStack w='full' h='full' spacing={4}>
      <HeaderImage imageUrl={'/Membership.jpg'} backgroundPosition='50%' />
      <Box p='3rem' px='5rem' alignItems='baseline' w='full'>
        <Text as='h1' fontSize='3xl' fontWeight='bold' color='brand.900' textAlign='center'>
          Membership
        </Text>

        <Text fontSize='xl' as='p' mt={10}>
        The World Forensics League offers different types of membership options to cater to the needs and interests of various groups involved in forensics activities.
        </Text>

        {membershipTypes.map((membershipType) => (
          <Box key={membershipType.type} pt='2rem'>
            <Text as='h2' fontSize='2xl' fontWeight='bold' color='brand.900'>
              {membershipType.type}
            </Text>
            <Text as='p' fontSize='xl' textAlign='justify'>
              {membershipType.description}
            </Text>
          </Box>
        ))}

        <Accordion allowToggle mt={10}>
          {membershipDetails.map((membershipDetail) => (
            <AccordionItem key={membershipDetail.header}>
              <AccordionButton>
                <Flex fontSize='2xl' fontWeight='bold' color='brand.900' alignItems='center'>
                  <Icon as={BsChevronDown} color='brand.900' mr={5}/>
                  {membershipDetail.header}
                </Flex>
              </AccordionButton>
              <AccordionPanel>
                {membershipDetail.body}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Flex direction='column' w='full' justifyContent='center' alignItems='center'>
          <Button size='lg' bg='brand.900' color='white' borderRadius='2rem' m='2rem' p='1rem' px='2rem' onClick={onOpen}>Register for Membership</Button>
          <Button size='lg' bg='secondary.900' color='white' borderRadius='2rem' p='1rem' px='2.5rem'>
            <Link href="https://docs.google.com/spreadsheets/d/1yWXDmyw6FG1u9oqTacqUxx4n-n01GMRnZfCl-zCvZUU/edit?usp=sharing" target="_blank">View Member Rankings</Link>
          </Button>
        </Flex>

        <MembershipRegistrationModal isOpen={isOpen} onClose={onClose} />
        <SuccessRegistrationModal isOpen={success === 'true'} onClose={onCloseSuccessHandler} type='memberships' />
      </Box>
    </VStack>
  );
}

export default MembershipsPage;
