import { Box, Flex, Input, Button, FormControl, FormLabel, Textarea, Center, useDisclosure, } from "@chakra-ui/react";
import { Form } from "react-router-dom";
import SuccessContactUsModal from "./SuccessContactUsModal";

const API_URL = process.env.REACT_APP_API_URL;

export const ContactUs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      email: event.target.email.value,
      name: event.target.name.value,
      message: event.target.message.value,
    };

    await fetch(`${API_URL}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    event.target.email.value = '';
    event.target.name.value = '';
    event.target.message.value = '';

    onOpen();
  };

  return (
    <Box w='100%' bg='brand.900' color='white'>
      <Flex direction='column' align='center' justify='center' p='3rem'>
        <Form onSubmit={handleSubmit} style={{width: '50%'}}>
            <FormControl id='email' isRequired color='white'>
              <FormLabel>Email address</FormLabel>
              <Input name='email' type='email' borderRadius='2rem' />
            </FormControl>
            <FormControl id='name' isRequired>
              <FormLabel>Name</FormLabel>
              <Input name='name' borderRadius='2rem' />
            </FormControl>
            <FormControl id='message' isRequired>
              <FormLabel>Message</FormLabel>
              <Textarea name='message' borderRadius='2rem' minH={100} />
            </FormControl>
            <Center>
              <Button size='lg' type='submit' bg='white' color='brand.900' borderRadius='2rem' m='2rem' p='1rem' px='2rem'>Send</Button>
            </Center>
        </Form>
      </Flex>

      <SuccessContactUsModal isOpen={isOpen} onClose={onClose} />
    </Box>
  )
};
