import { GridItem, VStack, Image, Text } from "@chakra-ui/react";
import { formatDate } from "../util/utils";
import { Link } from 'react-router-dom';

export const HomeEvent = ({ event }) => {
  const imageUrl = event.imageUrl || '/home-2.JPG';

  let dateContent = `${formatDate(event.startDate)} to ${formatDate(event.endDate)}`;

  if (!event.endDate) {
    dateContent = formatDate(event.date);
  }

  return (
    <GridItem _hover={{ textDecoration: 'underline', color: 'white' }}>
      <Link to={`/events/${event.id}?eventType=${event.type}`}>
        <VStack spacing={4}>
            <Image src={imageUrl} alt='cover photo' w='100%' objectFit='cover' h='100%' borderRadius='2rem' />
            <Text as='p' fontWeight='medium' color='white'>
              {event.title} ({dateContent})
            </Text>
        </VStack>
      </Link>
    </GridItem>
  );
};
