import { Box, Text, VStack, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { defer, useLoaderData, Await, useSearchParams } from 'react-router-dom';
import { Suspense } from 'react';
import { Event } from "../components/Event";

const API_URL = process.env.REACT_APP_API_URL;

function EventsPage() {
  const { events } = useLoaderData();
  const [ eventTypeParams, setEventTypeParams ] = useSearchParams();
  const eventType = eventTypeParams.get('eventType') || 'competitions';

  const onChangeHandler = (event) => {
    eventTypeParams.set('eventType', event.target.value);
    setEventTypeParams(eventTypeParams);
  };

  return (
    <>
        <Box px='5rem' py='3rem' w='full'>
          <VStack spacing={8} alignItems='baseline'>
            <Box w='20%'>
              <FormControl mt={4} key='eventType'>
                <FormLabel><Text fontSize='2xl' p='0'>Event Type</Text></FormLabel>
                <Select name='eventType' value={eventType} onChange={onChangeHandler} required>
                  <option value='competitions'>Competitions</option>
                  <option value='webinars'>Webinars</option>
                  <option value='workshops'>Workshops</option>
                  <option value='camps'>Camps</option>
                </Select>
              </FormControl>
            </Box>

            <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
              <Await resolve={events}>
                {(loadedEvents) => (
                  loadedEvents.map((event) => (
                    <Event key={event.id} event={event} eventType={eventType} />
                  ))
                )}
              </Await>
            </Suspense>
          </VStack>
        </Box>
    </>
  );
}

export default EventsPage;

async function loadEvents(eventType) {
  const response = await fetch(`${API_URL}/${eventType}`);

  if (!response.ok) {
    return [];
  }

  const data = await response.json();

  return data?.data?.events || [];
}

export const loader = ({ request }) => {
  const { searchParams } = new URL(request.url);
  const eventType = searchParams.get('eventType') || 'competitions';

  return defer({
    events: loadEvents(eventType),
  });
};
