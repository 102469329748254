import { Outlet, createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './layout/Root';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import HomePage, { loader as homeLoader } from './pages/Home';
import EventsPage, { loader as eventsLoader } from './pages/Events';
import EventsRoot from './pages/EventsRoot';
import EventDetails, { loader as eventDetailsLoader } from './pages/EventDetails';
import UpdatesPage, { loader as updatesLoader } from './pages/Updates';
import { action as competitionRegistrationAction } from './components/CompetitionRegistrationModal';
import { action as membershipRegistrationAction } from './components/MembershipRegistrationModal';
import UpdateDetailsPage, { loader as updateDetailsLoader } from './pages/UpdateDetails';
import Fonts from './Fonts';
import MembershipsPage from './pages/Memberships';
import ResourcesPage, { loader as updateResourcesLoader } from './pages/Resources';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: homeLoader,
      },
      {
        path: '/events',
        element: <EventsRoot />,
        children: [
          {
            index: true,
            element: <EventsPage />,
            loader: eventsLoader,
          },
          {
            path: ':id',
            element: <EventDetails />,
            loader: eventDetailsLoader,
          }
        ]
      },
      {
        path: '/resources',
        element: <ResourcesPage />,
        loader: updateResourcesLoader,
      },
      {
        path: '/updates',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <UpdatesPage />,
            loader: updatesLoader,
          },
          {
            path: ':id',
            element: <UpdateDetailsPage />,
            loader: updateDetailsLoader,
          }
        ]
      },
      {
        path: '/memberships',
        element: <MembershipsPage />
      },
      { path: '/contact', element: <h1>Contact</h1> },
      { path: '/api/register', action: competitionRegistrationAction },
      { path: '/api/memberships', action: membershipRegistrationAction },
    ],
  }
]);

const customTheme = extendTheme({
  colors: {
      brand: {
          100: "#db867b",
          900: "#d64123",
      },
      secondary: {
          100: "#5d82af",
          900: "#0C358f",
      },
  },
  fonts: {
    heading: `'Cosmica', sans-serif`,
    body: `'Cosmica', sans-serif`,
  },
});

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Fonts />
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
