import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

function SuccessContactUsModal(props) {
    return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Thanks for your message! </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text fontSize='lg'>We'll get back to you as soon as we can via email.</Text>
            </ModalBody>
            <ModalFooter>
            <Button variant="ghost" onClick={props.onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}

export default SuccessContactUsModal;
