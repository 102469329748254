import { Box, Text, VStack } from "@chakra-ui/react";
import { defer, useLoaderData, Await } from 'react-router-dom';
import { Suspense } from 'react';
import HeaderImage from "../components/HeaderImage";
import { ResourceFile } from "../components/ResourceFile";

const API_URL = process.env.REACT_APP_API_URL;

function EventsPage() {
  const { publicSpeaking, debate } = useLoaderData();

  return (
    <VStack w='full' h='full' spacing={4}>
      <HeaderImage imageUrl={'/Resources.jpg'} backgroundPosition='0 10%' />
        <Box px='5rem' py='3rem' w='full'>
          <Text as='h1' fontSize='3xl' fontWeight='bold' color='brand.900' textAlign='center' pb={10}>
            Resources
          </Text>

          <Box pb={10}>
            <Text as='h1' fontSize='2xl' fontWeight='bold' color='brand.900'>
              Public Speaking
            </Text>
            <VStack spacing={2} alignItems='baseline'>
              <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
                <Await resolve={publicSpeaking}>
                  {(loadedPublicSpeaking) => (
                    loadedPublicSpeaking.map((resource) => (
                      <ResourceFile key={resource.id} resource={resource} />
                    ))
                  )}
                </Await>
              </Suspense>
            </VStack>
          </Box>

          <Box pb={10}>
            <Text as='h1' fontSize='2xl' fontWeight='bold' color='brand.900'>
              Debate
            </Text>
            <VStack spacing={2} alignItems='baseline'>
              <Suspense fallback={<p style={{textAlign: 'center'}}>Loading...</p>}>
                <Await resolve={debate}>
                  {(loadedDebates) => (
                    loadedDebates.map((resource) => (
                      <ResourceFile key={resource.id} resource={resource} />
                    ))
                  )}
                </Await>
              </Suspense>
            </VStack>
          </Box>
        </Box>
    </VStack>
  );
}

export default EventsPage;

async function loadEvents(category) {
  const response = await fetch(`${API_URL}/resources?category=${category}`);

  if (!response.ok) {
    return [];
  }

  const data = await response.json();

  return data?.data?.events || [];
}

export const loader = () => {
  return defer({
    publicSpeaking: loadEvents('public speaking'),
    debate: loadEvents('debate'),
  });
};
